export default {
  /**
   * AUTH - API
   */
  auth: null,

  /**
   * USER - API
   */
  user: null,

  /**
   * PIN - API
   */
  pin: null,

  /**
   * BOX - API
   */
  box: null,

  /**
   * CART - API
   */
  storeCart: {},
  orders: {},

  /**
   * PRODUCTS - API
   */
  products: null,

  /**
  * GLOBAL LOADING - API
  */
  globalLoading: false,

  /**
  * TRANSLATIONS - API
  */
  translations: null,
  i18nData: null,

  /**
   * PAGE TITLE
   */
  pageTitle: null,
  pageBackUrl: null,
};
