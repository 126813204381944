/* eslint camelcase: ["error", {properties: "never"}] */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';

/**
 * Plugins
 * Import plugins here
 */
import './plugins/vue-axios';
import './plugins/vue-logger';
import './plugins/vue-anime';
import './plugins/element-ui';
import './plugins/dayjs';
import './plugins/vue-swiper';
import './plugins/vue-mask';
import './plugins/vue-idle';

/**
 * Main style
 * Import any additional style here
 */
import './assets/scss/styles.scss';

Vue.config.productionTip = false;

let deviceLanguage = '';

// set default language
if (localStorage.getItem('boxLang')) {
  deviceLanguage = localStorage.getItem('boxLang');
} else {
  deviceLanguage = 'de_AT';
}
localStorage.setItem('lang', deviceLanguage);

// init load language
store.dispatch('setI18nData', deviceLanguage).then((response) => {
  localStorage.removeItem(deviceLanguage);
  localStorage.removeItem('lang');
  localStorage.setItem(deviceLanguage, response.messages[`${deviceLanguage}`]);
  localStorage.setItem('lang', deviceLanguage);

  const i18n = new VueI18n({
    locale: deviceLanguage,
    fallbackLocale: deviceLanguage,
  });

  // set default messages
  i18n.setLocaleMessage(deviceLanguage, response.messages[`${deviceLanguage}`]);

  // update element UI component languages
  Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value),
  });

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
});
