// Add element-ui locale
import elementLocaleDE from 'element-ui/lib/locale/lang/de';
import elementLocaleEN from 'element-ui/lib/locale/lang/en';
import i18n from '../plugins/vue-i18n';

/* eslint no-underscore-dangle: ["error", { "allow": ["_vm"] }] */
export default {
  /**
   * AUTH
   */
  setAuth({ commit }) {
    commit('SET_AUTH');
  },

  /**
   * USER
   */
  setUser({ commit }) {
    commit('SET_USER');
  },

  /**
   * PIN
   */
  async setPin({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/user/pin`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PIN', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * BOX TOKEN
   */
  async setBox({ commit }, { payload }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/boxByToken`, {
        params: {
          token: payload.token,
        },
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });

      commit('SET_BOX', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * CART
   */
  async setCart({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/cart`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_CART', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async addItem({ commit }, { payload }) {
    const formData = new FormData();
    formData.append('articleEAN', payload.ean);
    formData.append('quantity', payload.quantity);

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/add`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('ADD_ITEM', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async addItemID({ commit }, { payload }) {
    const formData = new FormData();
    formData.append('articleID', payload.id);
    formData.append('quantity', payload.quantity);

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/add`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('ADD_ITEM', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async updateItem({ commit }, data) {
    if (data) {
      const formData = new FormData();
      formData.append('itemID', data.id);
      formData.append('quantity', data.quantity);
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/update`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      try {
        commit('UPDATE_ITEM', response.data);
        return true;
      } catch (e) {
        return e.status;
      }
    }
    return false;
  },

  async removeItem({ commit }, id) {
    const formData = new FormData();
    formData.append('itemID', id);

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/delete`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('REMOVE_ITEM', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async createOrder({ commit }) {
    this._vm.$http({
      url: `${process.env.VUE_APP_BASE_URL}/cart/createOrder`,
      method: 'post',
      headers: {
        'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
    })
      .then((response) => {
        commit('SET_ORDER', response.data);
        return true;
      })
      .catch((e) => {
        const st = e.status;
        return st;
      });
  },

  async getOrders({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/orders`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_ORDER', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async clearCart({ commit }) {
    const formData = new FormData();

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/empty`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('CLEAR_CART', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * PRODUCTS
   */
  async setProducts({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/cart/getManualArticles`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PRODUCTS', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * GLOBAL LOADING
   */
  setGlobalLoading({ commit }, status) {
    commit('SET_GLOBAL_LOADING', status);
  },

  /**
  * TRANSLATIONS
  */
  async setI18nData({ commit }, payload) {
    // this._vm.$http.defaults.timeout = 18000;
    // this._vm.$http.defaults.timeoutErrorMessage = 'timeout';

    try {
      const response = await this._vm.$http({
        method: 'get',
        url: `${process.env.VUE_APP_BASE_URL}/resources`,
        params: {
          locale: payload,
        },
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });

      // update language in localStorage + update store language
      const responseData = response.data;
      const translationsDE = {};
      const translationsEN = {};
      let translationsFinal = {};
      responseData.forEach((row) => {
        // if german
        if (row.localeID === 22) {
          translationsDE[row.code] = row.text;
        }
        // if english
        if (row.localeID === 352) {
          translationsEN[row.code] = row.text;
        }
      });
      // if german
      if (payload === 'de_AT') {
        translationsFinal = {
          ...translationsDE,
          ...elementLocaleDE,
        };
      }
      // if english
      if (payload === 'en_US') {
        translationsFinal = {
          ...translationsEN,
          ...elementLocaleEN,
        };
      }
      localStorage.removeItem(payload);
      localStorage.removeItem('lang');
      localStorage.setItem(payload, JSON.stringify(translationsFinal));
      localStorage.setItem('lang', payload);
      i18n.setLocaleMessage(localStorage.getItem('lang'), translationsFinal);
      commit('SET_I18N', response.data);
      return i18n;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * PAGE TITLE
   */
  addPageTitle({ commit }, title) {
    commit('ADD_PAGE_TITLE', title);
  },

  removePageTitle({ commit }, title) {
    commit('REMOVE_PAGE_TITLE', title);
  },

  addPageBackUrl({ commit }, url) {
    commit('ADD_PAGE_BACK_URL', url);
  },

  removePageBackUrl({ commit }, url) {
    commit('REMOVE_PAGE_BACK_URL', url);
  },
};
